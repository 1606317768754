//orderlist.js
import request from '@/utils/request'
import qs from 'qs'

// 查询订单
export function orderlist (id,page,limit,state) {
  let data = {
  id,page,limit,state
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/stafftodoorder',
    method: 'post',
    data
  })
}
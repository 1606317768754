<template>
	<div>
		<div>
			<div class="orderlist" v-for="item in orderlist" :key=item @click="showDetail(item.works_id)">
				<div class="ygdleft">
					<p class="biaoti">{{item.title}}</p>
					<p><span>{{item.name}}</span><span>{{item.editor}}</span></p>
				</div>
				<div class="ygdright">
					<div class="urgent" v-show="item.attribute==1">
						加急
					</div>
					<div style="height: 20px;"></div>
					<span>{{item.operation_time}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			src:"",	
			}
		},
		methods:{
		showDetail(wid){
		 this.$router.push({path:this.$parent.childpath,query:{id:this.$parent.now,wid:wid}})
			console.log(this.$parent)
		},//查询订单详情状态和编号
			
		},
		props:["orderlist"]
	}
</script>

<style>
	.orderlist{
		position: relative;
		display: flex;
		justify-content: space-between;
		background: #F7F7F7;
		border-radius: 4px;
		padding: 10px;
		margin-top: 10px;
		cursor: pointer;
	}
	.biaoti{
		font-size: 14px;
	}
	.orderlist span{
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin: 0 3px;
	}
	.urgent{
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 24px;
		background: #FF1918;
		border-radius: 0px 4px 0px 4px;
		color: #fff;
		text-align: center;
		font-size: 12px;
		line-height:24px;
	}
	.ygdPage{
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
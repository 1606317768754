<template>
	<div>
		<!--订单列表-->
		<div class="ygdtitle">
			已通过订单
		</div>
		<div v-if="!flag">
			<div v-if="orderlistlength==0" class="nodata">
				暂无数据
			</div>
			<div v-else>
				<order :orderlist="orderlist"></order>
				<div class="ygdPage">
					<el-pagination class="fy" small @size-change="handleSizeChange" :page-size="pagesize" :page-sizes="[10,20,30]" layout="total, sizes, prev, pager, next, jumper" @current-change="current_change" :total="total" background>
					</el-pagination>
				</div>
			</div>
		</div>
		<!--订单详情-->
	</div>
</template>

<script>
	import order from '../components/order'
	import { orderlist } from '@/API/YGD/orderlist';
	export default {
		components: {
			order
		},
		data() {
			return {
				flag: false,
				total: 0, //默认数据总数
				pagesize: 10, //每页的数据条数
				currentPage: 1, //默认开始页面
				now:2,//当前选中id
				num: "", //订单编号
				orderlist: [ //订单列表数据
				],
				orderlistlength: 0, //订单列表长度
				childpath:"/Staff/OrderPassed/Detail"
			}
		},
		methods: {
			// 分页
			current_change: function(currentPage) {
				this.currentPage = currentPage;
				this.todoOrder();
			},
			handleSizeChange(val) {
				console.log(val);
				this.pagesize = val;
				this.todoOrder();
			},
			//调取已通过订单接口
			todoOrder() {
				orderlist(localStorage.getItem('UserId'), this.currentPage, this.pagesize,2).then(res => {
					console.log(res);
					if(res.data.code == 0) {
						this.orderlist = res.data.data
						this.orderlistlength = this.orderlist.length;
						this.total = res.data.total
					}
				})
			}
		},
		prop: [""],
		mounted() {
			this.todoOrder();
		}

	}

</script>

<style>
.ygdtitle {


  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;

}
.num {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.num span {
  margin-right: 30px;
  cursor: pointer;
}
.num span:hover {
  color: #ff1918;

}
.ygdPage{
	display: flex;
	justify-content: center;
}
.stepicon {
  width: 70px;
}

.trace {
  background: #f7f7f7;
  border-radius: 4px;
}

.trace_title {
  padding-left: 20px;
  box-sizing: border-box;
  height: 30px;
  font-size: 14px;
}

.box {
  display: flex;
}

.box .trace_date {
  flex: 1;
}

.box .trace_date span {
  margin: 5px;
}

.box .trace_des {
  flex: 3;
}
.nodata {
  text-align: center;
  line-height: 80px;
  color: #e6e6e6;
}
</style>